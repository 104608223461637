.section_footer {
  background: #545454;
  padding: 30px 20px 0px 20px;
}

.section_footer .trending-destinations .para{
  width: 100%;
  color: #d2d2d2;
  font-size: 14px;
  margin-bottom: 7px;
}


.inboxform form {
  max-width: 560px;
  margin: auto;
}

.inboxform input {
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: transparent;
  padding: 10px;
  width: 100%;
  margin: 0px 0px 10px 0px;
  color: #d2d2d2;
}

.inboxform input::-webkit-input-placeholder {
  /* Edge */
  color: #d2d2d2;
}

.inboxform input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d2d2d2;
}

.inboxform input::placeholder {
  color: #d2d2d2;
}

.inboxform input:focus-visible {
  outline: 0px;
}

.inboxform button {
  background: #0081c3;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #0081c3;
  font-size: 13px;
  font-weight: 500;
  width: 50%;
  height: 100%;
}

.section_footer h2 {
  color: #fff;
  font-size: 20px;
}

.section_footer .row p {
  text-align: justify;
}

.section_footer h3 {
  color: #fff;
  padding-top: 5px;
  font-size: 20px;
}

.section_footer p {
  color: #d2d2d2;
  font-size: 14px;
  margin-bottom: 7px;
}

.section_footer span {
  color: #d2d2d2;
  padding-bottom: 20px;
  display: block;
  font-size: 15px;
}

.section_footer .white-logo {
  max-width: 267px;
  padding-bottom: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_footer a {
  color: #0081c3;
  text-decoration: none;
}

.section_footer hr {
  color: #9D9D9D;
  opacity: 100%;
  margin-bottom: 13px;
}

.copyright {
  display: flex;
  align-items: center;
}

.copyright p {
  margin: 0px;
}

@media screen and (max-width:400px) {
  .copyright .copyright-app .copy-img {
    flex-wrap: wrap;
  }
}

@media screen and (max-width:991px) {
  .copyright .copyright-app {
    justify-content: center;
  }

  .section_footer .copyright p {
    text-align: center;
  }

  .section_footer .white-logo {
    max-width: 145px;
  }
}

@media screen and (max-width:767px) {
  .inboxform form {
    max-width: 305px;
  }

  .section_footer {
    padding: 30px 0px 0px 0px;
  }

  .inboxform button {
    height: 95%;
  }
}