@media screen and (max-width:767px) {
    .phone-input-footer{
        margin-bottom: 12px;
    }
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 8px;
    line-height: 25px;
    height: 40px;
    width: 100%;
    outline: none;
}